<template>
  <div class="procedures-container">
    <span class="procedures-align">
      <ProcedureIcon v-if="item.type === 'Procedimento'" :class="`icon stroke procedure-icon`" />
      <TelemedicineIcon v-if="item.type === 'Telemedicina'" :class="`icon stroke procedure-icon`" />
      <AppointmentIcon v-if="item.type === 'Presencial'" :class="`icon stroke procedure-icon`" />
      <ReturnIcon v-if="item.type === 'Retorno'" :class="`icon stroke procedure-icon`" />
      <ExamIcon v-if="item.type === 'Exame'" class="exam" :class="`icon stroke procedure-icon`" />
      <SurgicalIcon v-if="item.type === 'Centro cirúrgico'" class="icon stroke surgical-icon" />
      <ProductIcon v-if="item.type === 'Produto'" class="icon stroke procedure-icon" />
    </span>
  </div>
</template>
<script>
export default {
  name: "ItemIcon",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
    }
  },
  components: {
    // Ellipsis: () => import('@/components/General/Ellipsis.vue'),
    // Tooltip: () => import('@/modules/schedule/components/Tooltip'),
    ExamIcon: () => import('@/assets/icons/exam.svg'),
    ProductIcon: () => import('@/assets/icons/product.svg'),
    AppointmentIcon: () => import('@/assets/icons/appointment.svg'),
    ProcedureIcon: () => import('@/assets/icons/procedure.svg'),
    ReturnIcon: () => import('@/assets/icons/return.svg'),
    SurgicalIcon: () => import('@/assets/icons/surgical.svg'),
    TelemedicineIcon: () => import('@/assets/icons/telemedicine.svg'),
  },
  methods: {
  }
}
</script>


<style scoped lang="scss">
.procedures-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--type-placeholder);

  .procedures-align {
    display: flex;
    align-items: center;

  }

  svg {
    width: 16px;
    height: 16px;
    fill: var(--type-placeholder);
    margin-right: 8px;

    &.surgical-icon path {
      fill: var(--type-placeholder);
    }
  }

  span {
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .icon {
    width: 20px;
    min-width: 20px;
    fill: var(--blue-500);

    &.surgical {
      width: 2vw;
      path {
        fill: var(--blue-500);
      }
    }
  }
  .icon2 {
    width: 20px;
    min-width: 20px;
    fill: var(--blue-500);
  }
  .icon3 {
    width: 20px;
    min-width: 20px;
    fill: var(--blue-500);
  }
  .type {
    color: var(--blue-500);

    &.surgical {
      width: 10vw !important;
    }
  }
}
</style>